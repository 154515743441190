import React, { useState, useEffect } from "react";
import Config from "../../config.json";
import axios from "axios";
import { useAuth } from "../../AuthContext";
import { MdDelete } from "react-icons/md";

function AssistantTable() {
  const [lists, setLists] = useState([]);
  const { user, jwtToken } = useAuth();

  useEffect(() => {
    const fetchAssistant = async () => {
      try {
        const response = await axios.get(`${Config["API_URL"]}/assistants`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
        });

        const assistants = response.data.assistants;
        setLists(response.data.assistants);
        console.log(lists);
        if (assistants && assistants.length > 0) {
          // setAssistantId(assistants[0].assistant_id);
        }
      } catch (error) {
        console.error("Error fetching assistants:", error);
      }
    };

    fetchAssistant();
  }, []);

  const handleDeleteAssistant = async (assistant_id) => {
    try {
      await axios.delete(`${Config["API_URL"]}/assistants/${assistant_id}`, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });

      setLists((prevLists) =>
        prevLists.filter((assistant) => assistant.assistant_id !== assistant_id)
      );
      console.log("User deleted successfully");
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  return (
    <>
      <div
        className="relative w-full px-12 overflow-x-auto h-80 mt-4"
        style={{
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
      >
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 rounded">
          <thead className="text-xs text-gray-700 uppercase bg-grey border-gray border-2 rounded dark:bg-gray-700 dark:text-gray-400">
            <tr>
              {/* <th scope="col" className="px-20 py-3">
              Name
            </th> */}
              <th scope="col" className="md:px-16 md:py-8 px-6 py-3">
                Name
              </th>
              <th scope="col" className="md:px-16 md:py-8 px-6 py-3">
                Id
              </th>
              <th scope="col" className="md:px-16 md:py-8 px-6 py-3">
                Model
              </th>
              <th scope="col" className="md:px-16 md:py-8 px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="overflow-y-auto">
            {lists.map((list, index) => (
              <tr
                key={list.id}
                className={`bg-white ${
                  index % 2 === 0 ? "bg-white" : "bg-grey bg-opacity-25"
                } border`}
              >
                {/* <td className="px-20 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {list.username}
                  </td> */}
                <td className="md:px-16 md:py-8 px-6 py-3">{list.name}</td>
                <td className="md:px-16 md:py-8 px-6 py-3">
                  {list.assistant_id}
                </td>
                <td className="md:px-16 md:py-8 px-6 py-3 text-xs md:text-sm">
                  {list.model}
                </td>
                <td className="md:px-16 md:py-8 px-6 py-3">
                  <button
                    className="text-blue-600 hover:underline pl-4 "
                    style={{ color: "red" }}
                    onClick={() => handleDeleteAssistant(list.assistant_id)}
                  >
                    <MdDelete size={20} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default AssistantTable;
