import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Navbar from "./Widgets/Navbar";
import Config from "../config.json";
import { useAuth } from "../AuthContext";
import axios from "axios";
import BarChart from "./Widgets/Barchart";
import { useParams } from "react-router-dom";

function UserAnalytics() {
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const today = new Date();
  const oneWeekBefore = new Date(today);
  oneWeekBefore.setDate(today.getDate() - 7);

  const { userId } = useParams();
  const { user, jwtToken } = useAuth();
  const [startDate, setStartDate] = useState(
    new Date(formatDate(oneWeekBefore))
  );
  const [endDate, setEndDate] = useState(new Date(formatDate(new Date())));
  const [messageCounts, setMessageCounts] = useState([]);
  const [feedbackCounts, setfeedbackCounts] = useState([]);
  const [activeTab, setActiveTab] = useState("pending");

  const fetchFeedbackCounts = async () => {
    try {
      const response = await axios.get(
        `${Config["API_URL"]}/feedback/noq/${userId}/?start_date=${
          startDate.toISOString().split("T")[0]
        }&end_date=${endDate.toISOString().split("T")[0]}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
      const counts = response.data.counts;
      setfeedbackCounts(counts);
    } catch (error) {
      console.error("Error fetching feedback counts:", error);
    }
  };

  const fetchMessageCounts = async () => {
    try {
      const response = await axios.get(
        `${Config["API_URL"]}/analytics/noq/${userId}/?start_date=${
          startDate.toISOString().split("T")[0]
        }&end_date=${endDate.toISOString().split("T")[0]}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
      const counts = response.data.counts;
      setMessageCounts(counts);
    } catch (error) {
      console.error("Error fetching message counts:", error);
    }
  };

  useEffect(() => {
    fetchMessageCounts();
    fetchFeedbackCounts();
  }, [startDate, endDate]);

  return (
    <>
      <Navbar />
      <div className="p-4 sm:ml-64 h-screen overflow-hidden">
        <div className="md:h-1/5 h-1/8 relative"></div>
        <div className="w-full md:h-4/5 h-7/8 flex flex-col items-center justify-center">
          <div className="w-full flex  md:px-12 md:mt-12 mt-4 mb-4">
            <div
              className={`  ${
                activeTab === "pending"
                  ? "bg-yellow"
                  : "border-2 border-gray text-gray bg-light-gray"
              } rounded-lg flex items-center justify-between`}
            >
              <button
                className="px-6 md:py-4 py-2"
                onClick={() => setActiveTab("pending")}
              >
                Message Query
              </button>
              <div className="rounded-full w-4 h-4 bg-dark-yellow mr-4"></div>
            </div>
            <div className="mx-4 h-auto w-0.5 bg-black"></div>
            <div
              className={` ${
                activeTab === "approved"
                  ? "bg-vesta-light"
                  : "border-2 border-gray text-gray bg-light-gray"
              } rounded-lg flex items-center justify-between`}
            >
              <button
                className="px-8 md:py-4 py-2"
                onClick={() => setActiveTab("approved")}
              >
                Feedback Query
              </button>
              <div className="rounded-full w-4 h-4 bg-green mr-4"></div>
            </div>
          </div>

          {activeTab === "pending" ? (
            <>
              <div className="w-full md:w-3/4">
                <h1 className="text-2xl font-bold text-gray-800 mb-4 text-center">
                  Message Counts for {userId}
                </h1>
                <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
                  <div className="flex flex-col space-y-2">
                    <label className="text-gray-700 font-semibold">
                      Start Date:
                    </label>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      dateFormat="yyyy-MM-dd"
                      className="p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    />
                  </div>

                  <div className="flex flex-col space-y-2">
                    <label className="text-gray-700 font-semibold">
                      End Date:
                    </label>
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      dateFormat="yyyy-MM-dd"
                      className="p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    />
                  </div>
                </div>

                <div className="pt-12">
                  <BarChart data={messageCounts} />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="w-full md:w-3/4">
                <h1 className="text-2xl font-bold text-gray-800 mb-4 text-center">
                  Negative Feedback Counts for {userId}
                </h1>
                <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
                  <div className="flex flex-col space-y-2">
                    <label className="text-gray-700 font-semibold">
                      Start Date:
                    </label>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      dateFormat="yyyy-MM-dd"
                      className="p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    />
                  </div>

                  <div className="flex flex-col space-y-2">
                    <label className="text-gray-700 font-semibold">
                      End Date:
                    </label>
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      dateFormat="yyyy-MM-dd"
                      className="p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    />
                  </div>
                </div>

                <div className="pt-12">
                  <BarChart data={feedbackCounts} />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default UserAnalytics;
