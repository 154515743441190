import React, { useState, useEffect } from "react";
import Navbar from "./Widgets/Navbar";
import { SlUser } from "react-icons/sl";
import { PiBellRingingBold } from "react-icons/pi";
import { PiHandWavingBold } from "react-icons/pi";
import { useAuth } from "../AuthContext";
import axios from "axios";
import Config from "../config.json";
import { useParams } from "react-router-dom";

function UpdateUser() {
  const { userId } = useParams();
  const { user, jwtToken } = useAuth();
  const [role, setRole] = useState("");
  const [mobile_num, setMobile_num] = useState("");
  const [email, setEmail] = useState("");
  const [language, setLanguage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `${Config["API_URL"]}/user/${userId}/`,
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
        const userData = response.data;
        console.log(response);
        setLanguage(userData.language);
        setRole(userData.role);
        setEmail(userData.email);
        setMobile_num(userData.mobile_num);
      } catch (error) {
        console.error("Error fetching user data:", error);
        setSuccessMessage("Error fetching user data");
      }
    };
    console.log(role);

    fetchUserData();
  }, [userId]);

  const handleUpdate = async () => {
    try {
      const response = await axios.patch(
        `${Config["API_URL"]}/user/update/${userId}/`,
        {
          language: language,
          role: role,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
      console.log("User updated successfully:", response.data);
      setSuccessMessage("User updated successfully");
    } catch (error) {
      console.error("Error updating user:", error);
      setSuccessMessage("Error updating user");
    }
  };

  return (
    <>
      <Navbar />

      <div class="p-4  sm:ml-64 h-screen">
        <div className="md:h-1/4 relative"></div>
        <div className="w-full h-3/4 border rounded-md flex flex-col items-center justify-center content-center">
          <SlUser size={75} />
          <div className="flex items-center justify-center content-center mt-8">
            <p className="text-xl flex">Mobile Number : </p>
            <p className="md:text-3xl text-xl font-bold flex text-vesta-green ml-4">
              {user.mobile_num}{" "}
            </p>
          </div>
          <div className="flex items-center justify-center content-center mt-2">
            <p className="text-xl flex">Email : </p>
            <p className="md:text-3xl text-xl font-bold flex text-vesta-green ml-4">
              {user.email}{" "}
            </p>
          </div>
          <div className="flex items-center justify-center content-center mt-2">
            <p className="text-xl flex">Language : </p>
            <select
              id="countries"
              className=" text-lg rounded-md border-1 border border-gray px-8 py-1 flex ml-4"
              onChange={(e) => {
                setLanguage(e.target.value);
              }}
            >
              <option selected>{language}</option>
              <option value="English">English</option>
              <option value="Hindi">Hindi</option>
              <option value="Punjabi">Punjabi</option>
            </select>
          </div>
          <div className="flex items-center justify-center content-center mt-2">
            <p className="text-xl flex">Role : </p>
            <select
              id="countries"
              class="text-lg rounded-md border-1 border border-gray px-8 py-1 flex ml-4"
              onChange={(e) => {
                setRole(e.target.value);
              }}
            >
              <option selected>{role}</option>
              <option value="admin">Admin</option>
              <option value="partner">Partner</option>
              <option value="operator">Operator</option>
            </select>
          </div>
          <p>{successMessage}</p>
          <button
            type="button"
            className="w-1/4 mt-12 border-2 py-2 px-6 rounded text-vesta-green"
            onClick={handleUpdate}
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
}

export default UpdateUser;
