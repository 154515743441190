import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Config from "../config.json";
import PhoneInput, { CountryData } from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function Register() {
  const [mobile_num, setMobileNumber] = useState("");
  const [username, setUserName] = useState("");
  const [email, setEmail] = useState(null);
  const [role, setRole] = useState("");
  const [language, setLanguage] = useState("");
  const [message, setMessage] = useState("");
  const [password, setPassword] = useState("");
  const [isdCode, setIsdCode] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");

  // console.log({
  //     username, email, mobileNumber
  // });

  const handlePhoneChange = (value, country) => {
    setMobileNumber(value.slice(country.dialCode.length));
    setIsdCode(country.dialCode);
    setSelectedCountry(country.countryCode);
  };

  const handleSignUp = async () => {
    try {
      // Make a POST request to your sign-up API endpoint
      const response = await axios.post(`${Config["API_URL"]}/signup/`, {
        email: email,
        mobile_num: mobile_num,
        isd_code: isdCode,
        role: role,
        language: language,
      });

      console.log("Sign Up Response:", response);
      console.log(response);
      if (response.status === 200) {
        // Sign up successful
        setMessage(
          "Sign up successful! You will be approved by the admin soon."
        );
        setPassword(response.data.password);
      } else {
        // Handle other response statuses
        setMessage("Sign up failed.");
      }
    } catch (error) {
      // Handle error
      setMessage("Error signing up. Please try again later.");
      console.error("Error signing up:", error);
    }
  };
  console.log(mobile_num);
  console.log(password);
  console.log(language);
  console.log(role);
  console.log(email);

  return (
    <>
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto h-screen lg:py-0">
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Sign in to your account
            </h1>
            <form className="space-y-4 md:space-y-4" action="#">
              {/* <div>
                  <label
                    for="email"
                    className="block text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Mobile Number
                  </label>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    className="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:border-gray-600 
                                dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#404041] dark:focus:border-[#404041]"
                    placeholder="Mobile number"
                    required=""
                    onChange={(e) => {
                      setMobileNumber(e.target.value);
                    }}
                  />
                </div> */}
              <div>
                <div className="pt-3">
                  {/* <input className="w-full h-12 px-2 py-2 rounded" type="text" placeholder='Phone' /> */}
                  <div>
                    <label
                      for="email"
                      className="block text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Mobile Number
                    </label>
                    <PhoneInput
                      country={selectedCountry || "in"} // Default to 'us' if no country is selected
                      value={isdCode + mobile_num}
                      inputStyle={{
                        height: "42px",
                        width: "100%",
                        border: "1px solid black",
                        borderRadius: "8px",
                      }}
                      onChange={handlePhoneChange}
                    />
                  </div>
                </div>
              </div>
              <div>
                <label
                  for="email"
                  className="block text-sm font-medium text-gray-900 dark:text-white"
                >
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:border-gray-600 
                                dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#404041] dark:focus:border-[#404041]"
                  placeholder="Email"
                  required=""
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
              {/* <div>
                                <label for="email" className="block text-sm font-medium text-gray-900 dark:text-white">Username</label>
                                <input type="text" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:border-gray-600 
                                dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#404041] dark:focus:border-[#404041]" placeholder="Username" required=""  onChange={(e)=>{setUserName(e.target.value)}}/>
                            </div> */}
              <div>
                <label
                  for="countries"
                  class="block text-sm font-medium text-gray-900 dark:text-white"
                >
                  Select an option
                </label>
                <select
                  id="countries"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400
                                 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  onChange={(e) => {
                    setLanguage(e.target.value);
                  }}
                >
                  <option selected>Choose a language</option>
                  <option value="English">English</option>
                  <option value="Hindi">Hindi</option>
                  <option value="Punjabi">Punjabi</option>
                </select>
              </div>
              <div>
                <label
                  for="countries"
                  class="block text-sm font-medium text-gray-900 dark:text-white"
                >
                  Select an option
                </label>
                <select
                  id="countries"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400
                                 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  onChange={(e) => {
                    setRole(e.target.value);
                  }}
                >
                  <option selected>Your Role</option>
                  <option value="partner">Partner</option>
                </select>
              </div>

              <div className="flex items-center justify-between"></div>
              <button
                type="button"
                className="w-full text-white bg-blue-600 hover:bg-primary-700 focus:ring-4 focus:outline-none 
                            focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 
                            dark:focus:ring-primary-800 bg-[#65c194]"
                onClick={handleSignUp}
              >
                Sign Up
              </button>
              {/* {password && <>
                                <p className="text-white">Use the generated password to login</p>
                                <p className="text-white">{password}</p>
                            </>} */}
              {message && (
                <>
                  <p>{message}</p>
                </>
              )}

              <div className="flex">
                <p className="block mb-2 text-sm font-medium text-gray-900 dark:text-white mr-2">
                  Already have an account?
                </p>
                <Link to="/login">
                  <p className="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-[#65c194]">
                    Sign in
                  </p>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Register;
