import React, { useState } from "react";
import Navbar from "./Widgets/Navbar";
import axios from "axios";
import { useAuth } from "../AuthContext";
import Config from "../config.json";
import AssistantTable from "./Widgets/AssistantTable";

function Assistant() {
  const [name, setName] = useState("");
  const [model, setModel] = useState("");
  const [type, setType] = useState("");
  const [message, setMessage] = useState("");
  const { user, jwtToken } = useAuth();
  const [activeTab, setActiveTab] = useState("pending");

  // console.log(jwtToken);
  // console.log(model);

  const createAssistant = async () => {
    const assistantData = {
      name,
      instructions: `Always respond in ${user.language} only. You are an assistant designed to assist caregivers in revising Standard Operating Procedures (SOPs) and domain knowledge on-demand, with the added capability of referencing a specific trainer handbook for Vesta Elder Care. It supports caregivers transitioning from one patient to another, enabling quick adaptation to each patient's needs by providing relevant information and SOP revisions. You will provide concise, accurate, and easily understandable responses to inquiries about procedures, protocols, patient care guidelines, and specific guidance from the Vesta Elder Care handbook. You prioritize safety, compliance with healthcare standards, and the provision of evidence-based recommendations. While the assistant will ask for clarification when necessary, it primarily focuses on delivering information efficiently to facilitate quick learning and application in a caregiving context. You have access to uploaded files for detailed reference, ensuring that the advice given aligns closely with the trainer handbook's guidelines and best practices.`,
      tools: [{ type: "file_search" }],
      model,
      type,
    };

    try {
      const response = await axios.post(
        `${Config["API_URL"]}/assistants/`,
        assistantData,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      ); // Replace with your FastAPI server URL
      setMessage(`Assistant created with ID:" ${response.data.assistant_id}`);
      console.log("Assistant created with ID:", response.data.assistant_id);
    } catch (error) {
      setMessage(`Error creating assistant: ${error}`);
      console.error("Error creating assistant:", error);
    }
  };

  return (
    <>
      <div className="overflow-hidden h-screen">
        <Navbar />
        <div className="p-4 sm:ml-64 h-screen flex flex-col items-center justify-center">
          <div className="md:h-1/4 relative"></div>
          <div className="w-full md:h-3/4 border rounded-md flex flex-col items-center relative">
            <div className="w-full flex  md:px-12 md:mt-12 mb-4 mt-4 px-2">
              <div
                className={`  ${
                  activeTab === "pending"
                    ? "bg-yellow"
                    : "border-2 border-gray text-gray bg-light-gray"
                } rounded-lg flex items-center justify-between`}
              >
                <button
                  className="px-6 md:py-4"
                  onClick={() => setActiveTab("pending")}
                >
                  Create an Assistant
                </button>
                <div className="rounded-full w-4 h-4 bg-dark-yellow mr-4"></div>
              </div>
              <div className="mx-4 h-auto w-0.5 bg-black"></div>
              <div
                className={` ${
                  activeTab === "approved"
                    ? "bg-vesta-light"
                    : "border-2 border-gray text-gray bg-light-gray"
                } rounded-lg flex items-center justify-between`}
              >
                <button
                  className="px-8 md:py-4 py-2"
                  onClick={() => setActiveTab("approved")}
                >
                  View Assistants
                </button>
                <div className="rounded-full w-4 h-4 bg-green mr-4"></div>
              </div>
            </div>
            {activeTab === "pending" ? (
              <>
                <div className="flex flex-col items-center justify-center contetnt-center text-center mt-12 md:px-0 px-10">
                  <div className="md:flex">
                    <p className="md:text-3xl text-xl font-semibold md:font-bold">
                      Currently we are using an assistant of type
                    </p>
                    <p className="md:text-4xl text-xl text-vesta-green font-bold md:ml-4 mt-1 md:mt-0">
                      Revision Bot
                    </p>
                  </div>
                  <p className="md:text-3xl text-xl font-semibold md:font-bold flex">
                    which is based on model gpt-3.5-turbo-0125.{" "}
                  </p>
                  {user.role === "admin" && (
                    <>
                      <div className="flex flex-col md:mt-1 mt-12">
                        <p className="md:mt-12 font-semibold md:text-2xl text-xl">
                          Create a new Assistant
                        </p>
                        <div className="flex flex-col md:flex-row justify-center md:items-center">
                          <div className="md:mt-5 mt-3">
                            <label className="md:text-xl text-lg mt-1 mr-2">
                              Name:
                            </label>
                            <input
                              type="text"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              required
                              className="border border-gray p-1 rounded"
                            />
                          </div>
                          <div className="md:mt-5 mt-3 md:ml-6">
                            <label className="md:text-xl text-lg mt-1 mr-2">
                              Model:
                            </label>
                            <select
                              onChange={(e) => setModel(e.target.value)}
                              required
                              className="border border-gray p-1 rounded"
                            >
                              <option selected>Choose a model</option>
                              <option value="gpt-3.5-turbo-0125">
                                gpt-3.5-turbo-0125
                              </option>
                            </select>
                          </div>
                          <div className="md:mt-5 mt-3 md:ml-6">
                            <label className="md:text-xl text-lg mt-1 mr-2">
                              Type
                            </label>
                            <select
                              onChange={(e) => setType(e.target.value)}
                              required
                              className="border border-gray p-1 rounded"
                            >
                              <option selected>Choose a type</option>
                              <option value="Revision-bot">Revision-bot</option>
                              {/* <option value="Quiz-bot">Quiz-bot</option> */}
                            </select>
                          </div>
                        </div>
                        <button
                          className="py-2 px-3 rounded mt-8 bg-vesta-green text-md font-semibold mb-4"
                          onClick={createAssistant}
                        >
                          Create Assistant
                        </button>
                        {message && <p>{message}</p>}
                      </div>
                    </>
                  )}
                </div>
              </>
            ) : (
              <>
                <AssistantTable />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Assistant;
