import React, { useState, useEffect } from "react";
import Config from "../../config.json";
import axios from "axios";
import { useAuth } from "../../AuthContext";

function PendinUserTable() {
  const [lists, setLists] = useState([]);
  const { user, jwtToken } = useAuth();
  //   console.log(jwtToken);
  useEffect(() => {
    const fetchPendingUsers = async () => {
      try {
        const response = await axios.get(
          `${Config["API_URL"]}/admin/pending-users/`,
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
        setLists(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching pending users:", error);
      }
    };

    fetchPendingUsers();
  }, []);

  const approveUser = async (user_id, approve) => {
    try {
      const response = await axios.post(
        `${Config["API_URL"]}/admin/approve-user/`,
        { user_id, approve },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
      setLists((prevLists) => prevLists.filter((user) => user.id !== user_id));

      return response.data;
    } catch (error) {
      console.error("Error approving user:", error);
      throw error;
    }
  };

  const handleApprove = async (user_id, approve) => {
    try {
      const result = await approveUser(user_id, approve);
      console.log("User approval result:", result);
      setLists((prevLists) => prevLists.filter((user) => user.id !== user_id));
    } catch (error) {
      console.error("Error approving user:", error);
    }
  };

  return (
    <>
      <div
        className="relative w-full md:px-12 overflow-x-auto h-80 mt-4"
        style={{
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
      >
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 rounded-md">
          <thead className="text-xs text-gray-700 uppercase bg-grey border-2 border-gray rounded-lg dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="md:px-16 md:py-8 px-8 py-4">
                Mobile Number
              </th>
              <th scope="col" className="md:px-16 md:py-8 px-8 py-4">
                Email
              </th>
              <th scope="col" className="md:px-16 md:py-8 px-8 py-4">
                Role
              </th>
              <th scope="col" className="md:px-16 md:py-8 px-8 py-4">
                Approve
              </th>
              <th scope="col" className="md:px-16 md:py-8 px-8 py-4">
                Reject
              </th>
            </tr>
          </thead>
          <tbody className="overflow-y-auto">
            {lists.map((list, index) => (
              <tr
                key={list.id}
                className={`bg-white ${
                  index % 2 === 0 ? "bg-white" : "bg-gray bg-opacity-25"
                } border`}
              >
                <td className="md:px-16 md:py-8 px-8 py-4">
                  {list.mobile_num}
                </td>
                <td className="md:px-16 md:py-8 px-8 py-4">{list.email}</td>
                <td className="md:px-16 md:py-8 px-8 py-4">{list.role}</td>
                <td className="md:px-16 md:py-8 px-8 py-4">
                  <button
                    className="text-blue-600 hover:underline"
                    onClick={() => handleApprove(list.id, true)}
                  >
                    Approve
                  </button>
                </td>
                <td className="md:px-16 md:py-8 px-8 py-4">
                  <button
                    className="text-red-600 hover:underline ml-4"
                    onClick={() => handleApprove(list.id, false)}
                  >
                    Reject
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default PendinUserTable;
