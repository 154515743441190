import React, { useState, useEffect } from "react";
import axios from "axios";
import Nav from "./Widgets/Nav";
import Chatbot from "./Chatbot";
import Navbar from "./Widgets/Navbar";
import { PiBellRingingBold } from "react-icons/pi";
import Config from "../config.json";
import { useAuth } from "../AuthContext";

function Playground({ assistantId }) {
  const [name, setName] = useState("");
  const { user, jwtToken } = useAuth();
  const [message, setMessage] = useState("");
  const [streams, setStreams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedStreamId, setSelectedStreamId] = useState(null);

  // const assistantId = "asst_FrHHHfZWkbVAPLOrmTfAqodD";

  const handleCreateStream = async () => {
    try {
      const response = await axios.post(
        `${Config["API_URL"]}/user/${user.id}/streams/`,
        null, // No data payload
        {
          params: {
            assistant_id: assistantId,
            name: name, // Use the name from state
          },
          headers: {
            Authorization: `Bearer ${jwtToken}`, // Include the token if needed for authentication
          },
        }
      );
      setMessage(`Stream created successfully: ${response.data.stream_id}`);
      setStreams([...streams, response.data]); // Add the new stream to the list
    } catch (error) {
      console.error("Error creating stream:", error);
      setMessage("Error creating stream");
    }
  };

  useEffect(() => {
    const fetchStreams = async () => {
      try {
        const response = await axios.get(
          `${Config["API_URL"]}/user/${user.id}/streams`,
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
        setStreams(response.data.streams);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setError("Error fetching stre");
        setLoading(false);
      }
    };

    fetchStreams();
  }, [user.id, jwtToken]);

  const handleStreamClick = (streamId) => {
    setSelectedStreamId(streamId);
  };

  return (
    <>
      <div className="h-screen">
        <Navbar />
        <div className="p-4 sm:ml-64 h-screen">
          <div className="md:h-1/4 relative"></div>
          <div className="md:h-3/4 h-full md:flex relative">
            <div className="md:w-1/4 w-full flex flex-col items-center h-1/5 md:h-full border rounded relative">
              {loading ? (
                <div>Loading streams...</div>
              ) : (
                <ul
                  className="mt-4 overflow-auto md:h-80 h-20"
                  style={{
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                  }}
                >
                  {streams.map((stream) => (
                    <li key={stream.stream_id}>
                      <button
                        onClick={() => handleStreamClick(stream.stream_id)}
                        className="w-64 py-2 mt-2 rounded shadow-lg"
                      >
                        {stream.name}
                      </button>
                    </li>
                  ))}
                </ul>
              )}
              {error && <div>{error}</div>}
              <div className="flex md:flex-col w-full absolute items-center justify-center justify-between bottom-0 md:my-4 p-2">
                <input
                  type="text"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  placeholder="Enter the name"
                  className="md:w-64 w-full border rounded md:my-4 p-2"
                ></input>
                <button
                  onClick={handleCreateStream}
                  className="md:w-64 w-full h-10 border rounded md:p-2 bg-vesta-light"
                  disabled={!name.trim()}
                >
                  Create Stream
                </button>
              </div>
            </div>
            <div className="md:w-3/4 w-full h-4/5 md:h-full overflow-hidden">
              {selectedStreamId ? (
                <Chatbot streamId={selectedStreamId} />
              ) : (
                <div className="flex items-center justify-center">
                  Select a stream to start chatting
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Playground;
