import React from "react";
import Nav from "./Widgets/Nav";
import { useAuth } from "../AuthContext";
import Chatbot from "./Chatbot";
import Upload from "./Widgets/Upload";
import Navbar from "./Widgets/Navbar";
import { PiHandWavingBold } from "react-icons/pi";
import { PiBellRingingBold } from "react-icons/pi";
import { Link } from "react-router-dom";
import packageJson from "../../package.json";
import AssistantTable from "./Widgets/AssistantTable";

function Home() {
  const { user, logout } = useAuth(); // Correctly call the useAuth hook

  if (!user) {
    return (
      <>
        <Navbar />
        <div class="p-4  sm:ml-64 h-screen">
          <div className="h-1/4 relative">
            <div className="absolute top-0 right-0 p-4">
              <PiBellRingingBold size={30} />
            </div>
          </div>
          <div className="w-full h-3/4 border rounded-md flex flex-col items-center justify-center content-center">
            <p className="text-4xl font-bold flex">
              {" "}
              Welcome to Vesta-Health Care <PiHandWavingBold />
            </p>
            <p>Your personal health guide assistant </p>
            <Link to="/login">
              <p className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Sign in
              </p>
            </Link>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      {/* <Nav /> */}
      <div className="overflow-hidden h-screen">
        <Navbar />
        <div class="p-4  sm:ml-64 h-screen flex flex-col items-center justify-center">
          <div className="md:h-1/5 relative"></div>
          <div className="w-full h-1/2 md:h-4/5 border rounded-md flex flex-col items-center justify-center content-center relative p-2">
            <img src="." alt="" />
            <p className="text-4xl font-bold flex">
              Welcome to Vesta-Health Care <PiHandWavingBold />
            </p>
            <p>Your personal health guide assistant </p>
            <p className="absolute bottom-0 text-xs">
              version {packageJson.version}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
