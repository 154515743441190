import React, { useState } from "react";
import Navbar from "./Widgets/Navbar";
import { PiBellRingingBold } from "react-icons/pi";
import PendinUserTable from "./Widgets/PendinUserTable";
import UserTable from "./Widgets/UserTable";

function Approve() {
  const [activeTab, setActiveTab] = useState("pending");

  return (
    <>
      <div className="overflow-hidden h-screen">
        <Navbar />
        <div className="p-4 sm:ml-64 h-screen flex flex-col items-center justify-center">
          <div className="md:h-1/5 relative"></div>
          <div className="w-full h-3/4 md:h-4/5 border rounded-md flex flex-col items-center relative p-2">
            <div className="w-full flex  md:px-12 md:mt-12 mt-4 mb-4">
              <div
                className={`  ${
                  activeTab === "pending"
                    ? "bg-yellow"
                    : "border-2 border-gray text-gray bg-light-gray"
                } rounded-lg flex items-center justify-between`}
              >
                <button
                  className="px-6 md:py-4 py-2"
                  onClick={() => setActiveTab("pending")}
                >
                  Pending Users
                </button>
                <div className="rounded-full w-4 h-4 bg-dark-yellow mr-4"></div>
              </div>
              <div className="mx-4 h-auto w-0.5 bg-black"></div>
              <div
                className={` ${
                  activeTab === "approved"
                    ? "bg-vesta-light"
                    : "border-2 border-gray text-gray bg-light-gray"
                } rounded-lg flex items-center justify-between`}
              >
                <button
                  className="px-8 md:py-4 py-2"
                  onClick={() => setActiveTab("approved")}
                >
                  Approved Users
                </button>
                <div className="rounded-full w-4 h-4 bg-green mr-4"></div>
              </div>
            </div>
            {activeTab === "pending" ? (
              <>
                {/* <p className="font-semibold text-3xl p-3">Pending User's List</p> */}
                <PendinUserTable />
              </>
            ) : (
              <>
                {/* <p className="font-semibold text-3xl p-3">Approved User's List</p> */}
                <UserTable />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Approve;
