import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./Components/Home";
import Update from "./Components/Update";
import Register from "./Components/Register";
import Login from "./Components/Login";
import Playground from "./Components/Playground";
import ProtectedRoute from "./Components/ProtectedRoute";
import Assistant from "./Components/Assistant";
import Profile1 from "./Components/Profile1";
import Approve from "./Components/Approve";
import axios from "axios";
import Config from "./config.json";
import ChangePassword from "./Components/ChangePassword";
import { useAuth } from "./AuthContext";
import UpdateUser from "./Components/UpdateUser";
import Analytics from "./Components/Analytics";
import UserAnalytics from "./Components/UserAnalytics";

function App() {
  const { user, jwtToken } = useAuth();
  const [assistantId, setAssistantId] = useState(null);

  useEffect(() => {
    const fetchAssistant = async () => {
      if (jwtToken) {
        try {
          const response = await axios.get(`${Config["API_URL"]}/assistants`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwtToken}`,
            },
          });
          const assistants = response.data.assistants;
          if (assistants && assistants.length > 0) {
            setAssistantId(assistants[0].assistant_id);
          }
        } catch (error) {
          console.error("Error fetching assistants:", error);
        }
      }
    };

    fetchAssistant();
  }, [jwtToken]);

  return (
    <Routes>
      <Route path="/" element={<ProtectedRoute element={Home} />} />
      <Route path="/register" element={<Register />} />
      <Route path="/login" element={<Login />} />
      <Route path="/change-password" element={<ChangePassword />} />
      <Route path="/assistant" element={<Assistant />} />
      <Route path="/profile" element={<ProtectedRoute element={Profile1} />} />
      <Route path="/approve" element={<ProtectedRoute element={Approve} />} />
      <Route
        path="/analytics"
        element={<ProtectedRoute element={Analytics} />}
      />
      <Route
        path="/update"
        element={<ProtectedRoute element={Update} assistantId={assistantId} />}
      />
      <Route
        path="/playground"
        element={
          <ProtectedRoute element={Playground} assistantId={assistantId} />
        }
      />
      <Route
        path="/user/update/:userId"
        element={<ProtectedRoute element={UpdateUser} />}
      />
      <Route
        path="/user/analytics/:userId"
        element={<ProtectedRoute element={UserAnalytics} />}
      />
    </Routes>
  );
}

export default App;
