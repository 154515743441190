import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const BarChart = ({ data }) => {
  const svgRef = useRef();
  const tooltipRef = useRef();

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    const width = 1000;
    const height = 300;
    const margin = { top: 20, right: 30, bottom: 40, left: 40 };

    // Create the scales
    const xScale = d3
      .scaleBand()
      .domain(data.map((d) => d.date))
      .range([margin.left, width - margin.right])
      .padding(0.1);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d.message_count)])
      .nice()
      .range([height - margin.bottom, margin.top]);

    // Add the axes
    const xAxis = d3.axisBottom(xScale).tickFormat((d) => d.slice(0, 10));
    const yAxis = d3.axisLeft(yScale).ticks(5);

    svg
      .append("g")
      .attr("transform", `translate(0,${height - margin.bottom})`)
      .call(xAxis)
      .selectAll("text")
      .attr("transform", "rotate(-45)")
      .style("text-anchor", "end");

    svg
      .append("g")
      .attr("transform", `translate(${margin.left},0)`)
      .call(yAxis);

    // Create the tooltip
    const tooltip = d3.select(tooltipRef.current);

    // Create the bars with hover functionality
    svg
      .selectAll(".bar")
      .data(data)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("x", (d) => xScale(d.date))
      .attr("y", (d) => yScale(d.message_count))
      .attr("width", xScale.bandwidth())
      .attr("height", (d) => yScale(0) - yScale(d.message_count))
      .attr("fill", "steelblue")
      .on("mouseover", (event, d) => {
        const [x, y] = [xScale(d.date), yScale(d.message_count)];
        tooltip
          .style("opacity", 1)
          .html(`${d.message_count}`)
          .style("left", `${x + xScale.bandwidth() / 2 - 25}px`)
          .style("top", `${y - 30}px`);
      })
      .on("mouseout", () => {
        tooltip.style("opacity", 0);
      });

    svg
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .style("overflow-x", "auto");
  }, [data]);

  return (
    <div className="relative overflow-x-auto w-full">
      <svg ref={svgRef}></svg>

      <div
        ref={tooltipRef}
        className="absolute bg-white text-sm pointer-events-none duration-200 py-2"
      ></div>
    </div>
  );
};

export default BarChart;
