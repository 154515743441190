import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { BsRobot } from "react-icons/bs";
import { FaHome, FaUser } from "react-icons/fa";
import { TbMessageChatbot } from "react-icons/tb";
import { LuFileStack } from "react-icons/lu";
import logo from "../images/vstaaaa.png";
import { useAuth } from "../../AuthContext";
import { FaCheck } from "react-icons/fa";
import { IoAnalyticsSharp } from "react-icons/io5";
import { IoCloseSharp } from "react-icons/io5";

function Navbar() {
  const { user, jwtToken } = useAuth();
  const [isSidebarOpen, setIsSidebarOpen] = useState();
  const sidebarRef = useRef(null);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    console.log("Closing sidebar"); // Debugging state change
    setIsSidebarOpen(false);
  };

  // Open sidebar function (separate from close)
  const openSidebar = () => {
    console.log("Opening sidebar");
    setIsSidebarOpen(true);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        closeSidebar();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sidebarRef]);

  return (
    <>
      <button
        onClick={isSidebarOpen ? closeSidebar : openSidebar} // Toggle based on sidebar state
        aria-controls="default-sidebar"
        type="button"
        className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 z-50 relative"
      >
        <span className="sr-only">
          {isSidebarOpen ? "Close sidebar" : "Open sidebar"}
        </span>
        {isSidebarOpen ? (
          <IoCloseSharp className="w-6 h-6" aria-hidden="true" /> // Cross icon when sidebar is open
        ) : (
          <svg
            className="w-6 h-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clipRule="evenodd"
              fillRule="evenodd"
              d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
            />
          </svg>
        )}
      </button>

      {/* Sidebar */}
      <aside
        ref={sidebarRef}
        id="default-sidebar"
        className={`fixed top-0 left-0 z-20 w-64 h-screen transition-transform bg-white ${
          isSidebarOpen ? "translate-x-0 bg-[#FFFFFF]" : "-translate-x-full"
        } sm:translate-x-0`}
        aria-label="Sidebar"
      >
        <div className="h-1/5">
          <div className="w-48 pt-8 pl-12">
            <p>
              <img src={logo} alt="Logo" className="mb-96" />
            </p>
          </div>
        </div>
        <div className="h-4/5 px-12 py-4 overflow-y-auto content-center">
          <ul className="space-y-2 font-medium">
            <li>
              <Link
                to="/"
                className="flex flex-col items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-vesta-light group border my-2"
              >
                <div className="">
                  <FaHome size={20} />
                </div>
                <span className="text-lg">Home</span>
              </Link>
            </li>
            <li>
              <Link
                to="/playground"
                className="flex flex-col items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-vesta-light group border my-2"
              >
                <div className="">
                  <BsRobot size={20} />
                </div>
                <span className="text-base">Playground</span>
              </Link>
            </li>
            <li>
              <Link
                to="/assistant"
                className="flex flex-col items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-vesta-light group border my-2"
              >
                <div className="">
                  <TbMessageChatbot size={20} />
                </div>
                <span className="text-base">Assistants</span>
              </Link>
            </li>
            {user.role === "admin" && (
              <>
                <li>
                  <Link
                    to="/update"
                    className="flex flex-col items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-vesta-light group border my-2"
                  >
                    <div className="">
                      <LuFileStack size={20} />
                    </div>
                    <span className="text-base">Files</span>
                  </Link>
                </li>

                <li>
                  <Link
                    to="/approve"
                    className="flex flex-col items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-vesta-light group border my-2"
                  >
                    <div className="">
                      <FaCheck size={20} />
                    </div>
                    <span className="text-base">User</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/analytics"
                    className="flex flex-col items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-vesta-light group border my-2"
                  >
                    <div className="">
                      <IoAnalyticsSharp size={30} />
                    </div>
                    <span className="text-base">Analytics</span>
                  </Link>
                </li>
              </>
            )}
            <li>
              <Link
                to="/profile"
                className="flex flex-col items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-vesta-light group border my-2"
              >
                <div className="">
                  <FaUser size={20} />
                </div>
                <span className="text-base">Profile</span>
              </Link>
            </li>
          </ul>
        </div>
      </aside>
    </>
  );
}

export default Navbar;
