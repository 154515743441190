import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import Config from "../config.json";

function ChangePassword() {
  const [identifier, setIdentifier] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const navigate = useNavigate();
  // const { login, message, user, logout } = useAuth();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(
        `${Config["API_URL"]}/password-reset/`,
        { identifier }
      );
      setMessage(response.data.message);
      setError("");
    } catch (error) {
      setError(error.response?.data?.detail || "An error occurred");
      setMessage("");
    }
  };

  const handleVerify = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(
        `${Config["API_URL"]}/password-verify/`,
        {
          identifier,
          verification_code: verificationCode,
          new_password: newPassword,
        }
      );
      setMessage(response.data.message);
      setError("");
    } catch (error) {
      setError(error.response?.data?.detail || "An error occurred");
      setMessage("");
    } finally {
      setIsButtonDisabled(false); // Re-enable the button if needed
    }
  };

  return (
    <>
      <section className="bg-white dark:bg-white">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0  dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Forgot Password?
              </h1>
              <form className="space-y-4 md:space-y-6" action="#">
                <div>
                  <label
                    for="email"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Mobile Number or Email address
                  </label>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 
                                  focus:border-primary-600 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Mobile number"
                    required=""
                    onChange={(e) => {
                      setIdentifier(e.target.value);
                    }}
                  />
                </div>
                <div className="flex">
                  <div className="basis-3/4">
                    <label
                      for="code"
                      className="mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Verification Code
                    </label>
                    <input
                      type="text"
                      placeholder="Ex 123456"
                      className="bg-gray-50 border border-gray-300 
                                  text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white 
                                  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required=""
                      onChange={(e) => {
                        setVerificationCode(e.target.value);
                      }}
                    />
                  </div>
                  <div className="basis-1/4 px-2 pt-6">
                    <button
                      type="button"
                      className="py-2 px-8 rounded bg-vesta-green"
                      onClick={handleSubmit}
                      disabled={isButtonDisabled}
                    >
                      Send
                    </button>
                  </div>
                </div>
                <div>
                  <label
                    for="code"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="••••••••"
                    className="bg-gray-50 border border-gray-300 
                                  text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white 
                                  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required=""
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                    }}
                  />
                </div>
                <div className="flex items-center justify-between"></div>
                <button
                  type="button"
                  className="w-full text-white bg-blue-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 
                              text-center dark:bg-[#65c194] dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                  onClick={handleVerify}
                >
                  Update Password
                </button>
                {/* <button type="button" className="w-full text-white bg-blue-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 
                              text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800" onClick={logout}>Sign in</button> */}
                <p>{message}</p>
                {error && <p>{error}</p>}
                <div>
                  <p className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Don't have an account?
                  </p>
                  <div className="flex justify-between">
                    <Link to="/register">
                      <p className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Sign Up
                      </p>
                    </Link>
                    <Link to="/login">
                      <p className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Login
                      </p>
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ChangePassword;
